import React, { useState } from 'react';
import './header.css';
import './why-idlog.css';

const WhyIdLogMenu = () => {
  // State to manage dropdown visibility
  const [isHovered, setIsHovered] = useState(false);

  // Handler for mouse enter and leave
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className="dropdown-container header-item" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <span className='dropdown-link'>Why Obics?</span>
      
      {/* Show dropdown only when hovered */}
      {isHovered && (
        <div className="menu-dropdown-inner-container">
            <div className='dropdown-placekeeper'></div>
            <div className="menu-dropdown">
            <ul>
                <li><a href="https://obics.io/analytics" className="menu-dropdown-link">Because of the OLAP analytics</a></li>
                <li><a href="https://obics.io/scale">Because of the scale</a></li>
                <li><a href="https://obics.io/log-unified">Because of the unified observability</a></li>
                <li><a href="https://obics.io/static-id">Because of the unique log IDs</a></li>
                {/* <li><a href="/analytics">Because of the Dashboards</a></li>
                <li><a href="/analytics">Because of the Alerts</a></li> */}
                
            </ul>
            </div>
        </div>
      )}
    </div>
  );
};

export default WhyIdLogMenu;