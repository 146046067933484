import * as React from "react"
import "./header.css"
// import DropdownMenu from "./DropDownMenu"
import { useState } from "react"
import logo from './idlogLogo5.png';
import WhyIdLogMenu from "./why-idlog";
import { auth } from "../firebase";
import { User } from "firebase/auth";
// import AvatarCircle from "./avatar-circle";
import { isSandboxUser } from "../utils/url-utils";

interface HeaderProps {
  showLogout: boolean;
  user: User;
}

const Header = ({ showLogout, user }: HeaderProps) => {

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <a href="https://obics.io/">
        <img
          src={logo}
          height={61}
          alt="Obics logo"
        />
      </a>

      <button className="hamburger" onClick={toggleMenu}>
        {/* Icon for Hamburger Menu */}
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </button>

      <ul className={`nav-links ${isOpen ? 'open' : ''}`}>
        <a href="https://obics.io/">
          Home
        </a>

        <WhyIdLogMenu />

        <a href="https://obics.io/sandbox-intro">
          Sandbox
        </a>
        <a href="https://obics.io/contact-us/?reason=other">
          Contact
        </a>
        {showLogout && (
          
          <a href="#" onClick={() => auth.signOut()}>
            Logout
          </a>
        )} 
        {(!user || isSandboxUser(user)) && 
        (
          <a href="https://obics.io/contact-us/?reason=trial" >
            <button className="action-button-dark" style={{ margin: 0, cursor: "inherit" }}>
              Free Trial
            </button>
          </a>
        )}
        {/* {user && <AvatarCircle name={user.displayName} /> */}

      </ul>
    </nav>
  );
}

export default Header
