import React, { useImperativeHandle, useRef, forwardRef, useEffect } from 'react';
import { editor, Position } from 'monaco-editor';
import * as monaco from 'monaco-editor';
import { getIntellisenseSuggestions } from './monaco-intellisense';
import logger from '../utils/logger';


interface MonacoEditorProps {
  ctrlEnterClicked: () => void;
  index: number;
  isSandBox: boolean;
  height: number;
}


monaco.languages.setMonarchTokensProvider('sql', {
  tokenizer: {
    root: [
      [/\b(?:SELECT|FROM|WHERE|INSERT|UPDATE|DELETE|JOIN|INNER JOIN|LEFT JOIN|RIGHT JOIN|GROUP BY|ORDER BY|LIMIT|OFFSET)\b/, 'keyword'],
      [/\b(?:WITH)\b/, 'keyword'],
      [/\b(?:VISUALIZE TIMECHART|VISUALIZE PIECHART)\b/, 'type'],
      [/\b(?:Logs)\b/, 'variable'],
    ],
  },
});
   

monaco.languages.registerCompletionItemProvider('sql', {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
  provideCompletionItems: (_model: editor.ITextModel, _position: Position, _context: any) => {
    const suggestions = getIntellisenseSuggestions();
    return { suggestions };
  },
});

const editorInstance = [];
const MonacoEditor = forwardRef((props: MonacoEditorProps, ref) => {

  const emitCtrlEnterClicked = props.ctrlEnterClicked;

  useImperativeHandle(ref, () => ({
    callGetValue: getValue,
    callLayout: () => editorInstance[props.index]?.layout()
    
  }));


  const monacoRef = useRef(null);


  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
  const handleEditorChange = (_value: any, _event: any) => {
    // const val = editorInstance[props.index].getValue();
  };

  const getValue = () => {
    return editorInstance[props.index]?.getValue();
  };

  useEffect(() => {
    if (monacoRef.current) {
      if (editorInstance[props.index]) {
        editorInstance[props.index].dispose();
      }

      const timeColumn = props.isSandBox ? 'Time' : 'time';

      editorInstance[props.index] = monaco.editor.create(monacoRef.current, {
        value: `SELECT * FROM Logs ORDER BY ${timeColumn} Desc LIMIT 100`,
        language: 'sql',
        
        scrollbar: { vertical: 'hidden', horizontal: 'auto' },
        minimap: { enabled: false },
        overviewRulerLanes: 0,
        glyphMargin: false,
        lineDecorationsWidth: 0,
        lineNumbersMinChars: 2,
      });
      
      const editor = editorInstance[props.index];
      
      setTimeout(() => {
        editor.focus();
      }, 10);

      const handleResize = () => {
        editor.layout();
      };
  
      // Add resize listener
      window.addEventListener("resize", handleResize);
  

      editor.onChange = handleEditorChange;
      editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.Enter, function () {
        logger.info('Ctrl+Enter pressed on index ' + props.index, 'XBDol');
        emitCtrlEnterClicked();
      });

      return () => {
        editor.dispose();
        editorInstance[props.index] = null;
        window.addEventListener("resize", handleResize);
      }
    }

  }, []);



  return (<div ref={monacoRef} style={{  height: props.height.toString() + '%', maxWidth: '100%', border: '1px solid gray' }} />);
  
});

export default MonacoEditor;


