import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import { ColDef } from 'ag-grid-community';
import logger from '../utils/logger';


interface TableProps {
    visible: boolean;
    height: number;
}

const Table = forwardRef((props: TableProps, ref) => {

    useImperativeHandle(ref, () => ({
        callUpdateTable: updateTable,
      }));

    const updateTable = (results) => {
        logger.verbose(`table updateTable results.length=${results?.length}`, "Yqi7j");

        if (results.length === 0 ) {
            //[{field: "result"}];
            setRowData([]);
        } else {
            if (!Array.isArray(results)){
                results = [results];
            }

            const firstRow = results[0];
            const cols = Object.keys(firstRow).map((key) => ({ field: key, headerName: key }));
            setColDefs(cols);
            setRowData(results);
        }
    }
    
    // Row Data: The data to be displayed.
    const [rowData, setRowData] = useState([]);
    
    // Column Definitions: Defines the columns to be displayed.
    const [colDefs, setColDefs] = useState<ColDef[]>([]);
    
    const defaultColDef: ColDef = {
        flex: 1,
        filter: true,
        resizable: true,
        
    };
    
    let lastRow = undefined;

    const onSelectionChanged = (ev) => {
        const selectedRows = ev.api.getSelectedRows();
        lastRow = selectedRows[0];
    }


    const onCellKeyDown = (event) => {
        
        // Copy if Ctrl (or Command) and 'C' are pressed
        const isCtrlOrCmdPressed = event.event.ctrlKey || event.event.metaKey; // MetaKey is used for Mac (Cmd)
        const isCPressed = event.event.key === 'c' || event.event.key === 'C';
        if (isCtrlOrCmdPressed && isCPressed) {
            const selectedText = window.getSelection().toString();
            if (!selectedText) {
                const text = Object.values(lastRow).join(' ');
                navigator.clipboard.writeText(text);
            }
        }
    }

    
    return (
        <div
            className="ag-theme-quartz results-view" // applying the Data Grid theme
            style={{ 
                height: props.height.toString() + '%',
                visibility: props.visible ? 'visible' : 'hidden',
                display: props.visible ? 'block' : 'none'
            }} // the Data Grid will fill the size of the parent container
        >
            <AgGridReact
                rowData={rowData}
                columnDefs={colDefs}
                defaultColDef={defaultColDef}
                rowHeight={30}
                headerHeight={35}
                preventDefaultOnContextMenu={true}
                copyHeadersToClipboard={true}
                autoSizeStrategy={{type: 'fitCellContents'}}
                rowSelection={{mode: 'singleRow',enableClickSelection: true,copySelectedRows: true,checkboxes: false}}
                onSelectionChanged={onSelectionChanged}
                onCellKeyDown={onCellKeyDown}
                gridOptions={{
                    enableCellTextSelection: true,
                }}
            />
        </div>
    );
});

export default Table;