import { User } from "firebase/auth";
import logo from './obics-logo-white-text.png';
import "./sidebar.css";
import SidebarLink from "./sidebar-link";
import { faCode, faChartSimple, faHistory, faBell, faCircleNodes, faClipboardList, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface SidebarProps {
  user: User;
  setIsProfileHovered: (isProfileHovered: boolean) => void;
}

const Sidebar = ({ user, setIsProfileHovered }: SidebarProps) => {



  const handleMouseEnter = () => {
    setIsProfileHovered(true);
  };

  const handleMouseLeave = () => {
    setIsProfileHovered(false);
  };

  return (
    <div id="sidebar">
      <div id="sidebar-top">

        <a href="https://obics.io">
          <img
            id="sidebar-logo1"
            src={logo}
            height={35}
            alt="Obics logo"
          />
        </a>

        {/* // TODO: Place on bottom */}
        <SidebarLink icon={faCode} link="/query" text="Query" enabled={true} />
        <SidebarLink icon={faHistory} link="/tail" text="Live Tail" enabled={false} />
        <SidebarLink icon={faChartSimple} link="/dashboards" text="Dashboards" enabled={false} />
        <SidebarLink icon={faBell} link="/alerts" text="Alerts" enabled={false} />
        <SidebarLink icon={faCircleNodes} link="/service-map" text="Service Map" enabled={false} />
        <SidebarLink icon={faClipboardList} link="/issues" text="Issues" enabled={false} />
      </div>
      <div id="sidebar-bottom">
        <div id="profile" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <FontAwesomeIcon icon={faUser} className="user-icon" />
          <span id="user-display-text">{user.displayName ?? user.email}</span>
        </div>
        
      </div>
    </div>

  );
}

export default Sidebar
