import './challenges.css';
import ChallengeSolution from './challenge-solution';


const Challenge2 = (() => {



  return (
    <div className="challenge-inner-container">
      <p>In a real-time analytics database, giving each log statement a unique ID is very beneficial (though not mandatory).
        Log IDs makes it unnecessary to rely on error-prone methods like <strong>log patterns</strong>. 
        For example, for this sandbox, our app that has the following log:</p>
      <pre><code>{"logger.Info('apag' /* a unique log ID */, \n`Analytics.pageView uid=${req.uid}\nurl=${req.originalUrl} stats=${JSON.stringify(stats)}`);"}</code></pre>


      <p>For this log, the unique ID is <strong>apag</strong>. Try querying for 100 logs of this type by using the ID.</p>
      <ChallengeSolution solutionQuery="SELECT * FROM Logs WHERE LogId = 'apag' LIMIT 100"/>
      <p>This log shows the page URL whenever the user entered a page. Extract the url from the log's message using REGEXP_EXTRACT().</p>




      <ChallengeSolution solutionQuery="SELECT extract(Message, 'url=(.*) stats=') AS url
FROM Logs WHERE LogId = 'apag' LIMIT 100"/>

      <p><strong>Bonus: </strong>Find the most common URL (query should return a single row).</p>
      <ChallengeSolution solutionQuery="SELECT COUNT(*) AS cnt, extract(Message, 'url=([^\s]+)') as Url 
FROM Logs 
WHERE LogId = 'apag'
GROUP BY 2
ORDER BY cnt DESC
LIMIT 1"/>

    </div>
  );
});

export default Challenge2;
