import React from 'react';
import { useEffect, useState } from 'react';
import Login from './login2/login';
import { onAuthStateChanged, User } from 'firebase/auth';
import { auth } from './firebase';
import Header from './header/header';
import './common.css';
import QueryTabs from './query/query-tabs';
import logger from './utils/logger';
import Sidebar from './sidebar/sidebar';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";

function App() {
  const notLoggedIn = 'not-logged-in' as const;
  const [user, setUser] = useState<User | typeof notLoggedIn | null>(null); // null means still loading

  const [isProfileHovered, setIsProfileHovered] = useState(false);
  const [isProfileMenuHovered, setIsProfileMenuHovered] = useState(false);


  useEffect(() => {
    const authStateChangedCleanup = onAuthStateChanged(auth, (user) => {
      if (user) { // User is logged in
        setUser(user);
        setIsProfileMenuHovered(false);

        logger.info(`User logged in. email: ${user.email} uid: ${user.uid} `, "KAj4m");
        logger.flush();
      } else { // User is logged out
        setUser(notLoggedIn);
        logger.info(`onAuthStateChanged: User is logged out`, "VS5D5");

      }
    });

    return () => authStateChangedCleanup();
  }, []);

  return (
    <div id="app-container">
      <Router>
        {user !== null && user !== notLoggedIn ? (
          <div >
            <Sidebar user={user} setIsProfileHovered={setIsProfileHovered} />
            <div id="content">
              <Routes>
                <Route path="/" element={<Navigate to="/query" replace />} />
                <Route path="/query" element={<QueryTabs user={user} />} />
                <Route path="/dashboards" element={<div>dashboards</div>} />
              </Routes>
              {(isProfileHovered || isProfileMenuHovered) && (
                <div id="profile-menu-container" onMouseEnter={() => setIsProfileMenuHovered(true)} onMouseLeave={() => setIsProfileMenuHovered(false)}>
                  <div id="profile-menu-content">
                    <a href="#" onClick={() => auth.signOut()}>Logout</a>
                  </div>
                </div>)}


            </div>
            
          </div>
        ) : (
          // user logged out, showing login page
          <div>
            <Header user={undefined} showLogout={false} />
            {user === notLoggedIn && <Login />}
            {user === null && <div>Loading...</div>}
          </div>
        )}
      </Router>
      
    </div>
  );
}

export default App;
