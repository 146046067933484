import './challenges.css';
import ChallengeSolution from './challenge-solution';


const Challenge4 = (() => {



  return (
    <div className="challenge-inner-container">
      
      {/* TODO: The idea here is to correlate an error to an earlier log. For that, we need better errors */}
      <p>A common use case for piecharts is to show the distribution of a field. For example, let's show the distribution of the <strong>Level</strong> field in all our logs.</p>
        
            
        <ChallengeSolution solutionQuery="SELECT count(*) as Times, Level FROM Logs 
GROUP BY Level"
/>
<p>You can visualize a piechart by choosing "Piechart" in the options button near "Run" on top of the page.</p>
<p><strong>Bonus: </strong>Try extracting the status code from Error logs and show the distribution of status codes in a piechart.</p>

<ChallengeSolution solutionQuery="SELECT Count(*) as times,  regexp_extract(Message, 'statusCode=([^\s]+)') AS statusCode FROM Logs 
WHERE Level = 'Error'
GROUP BY 2"
/>

    </div>
  );
});

export default Challenge4;
