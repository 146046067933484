// src/firebase.ts
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyATkJAV4SwukXPkF7tMJJ4IZr6SC_ZBkU8",
  authDomain: "idlog-app.firebaseapp.com",
  projectId: "idlog-app",
  storageBucket: "idlog-app.appspot.com",
  messagingSenderId: "860618631316",
  appId: "1:860618631316:web:c1bfac13e268a0823a2c81"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);