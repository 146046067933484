import React, { useState } from 'react';
import './challenges.css';


const ChallengeSolution = ({ solutionQuery, solutionText = undefined }) => {
  
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = (event) => {
    event.preventDefault(); // Prevent the default link behavior
    setIsOpen(!isOpen);
  };
  
  return (
    <div className='challenge-solution-container'>
      <a href="#" onClick={handleClick}><span className='solution-arrow'>{isOpen? "▼" : "►"}</span> See solution</a>
      {isOpen ? (<pre><code>{solutionQuery}</code></pre>) : (<span/>)}
      {isOpen && !!solutionText ? (<p>{solutionText}</p>) : (<span/>)
      
      }
    </div>

  );
};

export default ChallengeSolution;
