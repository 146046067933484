import { useState } from 'react';
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";
import { isSandboxHostname } from '../utils/url-utils';
import logger from '../utils/logger';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // check if current url is localhost
  
  // get the "isSandbox" query parameter
  if (isSandboxHostname()) {
    logger.info('This is a sandbox environment, logging in with sandbox user', 'qCWNr');
    signInWithEmailAndPassword(auth, 'sandbox@idlog.io', '5zEJp71c9NFwpQ');
    // remove query parameter from URL
    window.history.replaceState({}, document.title, '/');
    return <div></div>;
  }
    

  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      logger.info("handleLogin: User logged in! " + email, 'osw2w');//  + email + "auth" + auth + "password" + password);
    } catch (error) {
      console.error("Error logging in:", error);
    }
  };

  // const handleLogout = async () => {
  //   try {
  //     await signOut(auth);
  //     logger.info("handleLogout: User logged out!");
  //   } catch (error) {
  //     console.error("Error logging out:", error);
  //   }
  // };

  return (
    <div style={{textAlign: 'center'}}>
      <h1>Login</h1>
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email"
      />
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
      />
      <button onClick={handleLogin}>Login</button>
      {/* <button onClick={handleLogout}>Logout</button> */}
    </div>
  );
};

export default Login;
