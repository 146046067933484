import React, { useEffect, useRef, useState } from 'react';
import './query-tabs.css';
import Query from './query';
import Challenges from './sandbox/challenges';
import { User } from 'firebase/auth';
import { isSandboxUser } from '../utils/url-utils';
import logger from '../utils/logger';

interface QueryTabProps {
  user: User;
}

const QueryTabs: React.FC<QueryTabProps> = ({user}) => {

  logger.info(`QueryTabs user: ${user}`, 'xeyot');
  
  const [isCtrlPressed, setIsCtrlPressed] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [tabList, setTabList] = useState([
    { label: 'Tab 1' },
  ]);

  const editorRef = useRef({});

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === 'Enter' && !isCtrlPressed) {
        setIsCtrlPressed(true);
        runQueryForActiveTab();
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  
  
  
  const runQueryForActiveTab = () => {
    tabList.forEach((tab, index) => {
      editorRef.current[index].callRunQueryIfActive();
    });
  };

  // Function to switch tabs
  const handleTabClick = (index) => {
    logger.info('Tab clicked:' + index, '8vccu');
    setActiveTabIndex(index);
    
  };

  const handleNewTab = ()=> {
    logger.info('New Tab clicked', 'oSgB7');
    const numTabs = tabList.length;
    const newTabList = tabList.slice();
    newTabList.push({ label: `Tab ${numTabs + 1}` });
    setTabList(newTabList);
    setActiveTabIndex(numTabs);
  }

  function handleCloseTab(event, index: number): void {
    logger.info('handleCloseTab: active index:' + activeTabIndex + ' close index: ' + index, '9w3JU');
    event.stopPropagation();

    const newTabList = tabList.slice();
    newTabList.splice(index, 1);
    setTabList(newTabList);
    const newActiveTabIndex = activeTabIndex === index ? 0 : activeTabIndex < index ? activeTabIndex : activeTabIndex - 1;
    setActiveTabIndex(newActiveTabIndex);
  }

  return (
    <div className="query-tabs-container">
      {isSandboxUser(user) &&
        <div className="challenge-container">
          <Challenges />
        </div>
      }
      <div className={'tab-container' + (isSandboxUser(user) ? ' padding-for-challenge' : '')} >
        {/* Tab Labels */}
        <div className="tabs">
          {tabList.map((tab, index) => (
            <button
              key={index}
              className={(activeTabIndex === index ? 'active' : '') + ' tab-button'}
              onClick={() => handleTabClick(index)}
            >
              <span className='tab-button-text'>{tab.label}</span>
              <button className='close-tab-btn' onClick={(ev) => handleCloseTab(ev, index)}>x</button>
            </button>
          ))}
          <button
              className='tab-button'
              style={{ padding: '0 8px' }}
              onClick={() => handleNewTab()}
            >+</button>
        </div>

        {/* Tab Content - Render the component of the active tab */}
        <div className="tab-content">
          {/* {tabList[activeTabIndex].component} */}
          {tabList.map((tab, index) => (
            <div
              key={index}
              style={{ height: '100%', display: activeTabIndex === index ? 'block' : 'none' }}
            >
              <Query index={index} isActive={index === activeTabIndex} ref={ref => editorRef.current[index] = ref} ctrlEnterClicked={runQueryForActiveTab} user={user}/>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default QueryTabs;