// import * as React from "react"
import { useRef, useState, useImperativeHandle, forwardRef, useEffect } from "react";

import MonacoEditor from "./monaco-editor"
import './query.css';
import Timechart from "./timechart";
import Table from "./table";
import Piechart from "./piechart";
import { User } from "firebase/auth";
import { isSandboxUser } from "../utils/url-utils";
import logger from "../utils/logger";


enum VisualizationType {
  TABLE = 'table',
  TIMECHART = 'timechart',
  PIE = 'pie',
}

interface QueryProps {
  index: number;
  isActive: boolean;
  ctrlEnterClicked: () => void;
  user: User;
}

const SPLITTER_HEIGHT = 3;
const Query = forwardRef((props: QueryProps, ref) => {

  const editorRef = useRef(null);
  const tableRef = useRef(null);
  const timechartRef = useRef(null);
  const piechartRef = useRef(null);
  const [isRunDisabled, setIsRunDisabled] = useState(false);
  const [visualizationType, setVisualizationType] = useState(VisualizationType.TABLE);
  const [results, setResults] = useState([]);

  useImperativeHandle(ref, () => ({
    callRunQuery: onRunQuery.bind(this),
    callRunQueryIfActive: onRunQuery.bind(this),
  }));



  const onRunQuery = () => {
    logger.info('onRunQuery index ' + props.index + ' isActive: ' + props.isActive, "6x8T1");
    if (!props.isActive) {
      return;
    }

    if (isRunDisabled) {
      return;
    }

    const queryCodeInitial = editorRef.current.callGetValue();
    // eslint-disable-next-line prefer-const
    let [queryCode, newVisualizationType] = processQueryCode(queryCodeInitial);
    if (!newVisualizationType) {
      newVisualizationType = visualizationType;
    }

    setIsRunDisabled(true);
    const uid = props.user.uid;
    props.user.getIdToken().then(token => {
      logger.debug(`onRunQuery: uid: ${uid}`, "6x8T2");//, token: ${token}

      const localhost = true; // Change during DEBUG
      const reallyLocalhost = localhost && window.location.hostname === 'localhost';
      const queryApiUrl = reallyLocalhost ? 'http://localhost:8000/api/query' : 'https://obics.io/api/query/';
      fetch(queryApiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "uid": uid,
          "Authorization": "Bearer " + token,
          'x-session-id': logger.getSessionId(),
        },
        body: JSON.stringify({
          "query": queryCode,
        })
      })
        .then(response => response.json()) // Parse the response as JSON
        .then(resp => {

          if (resp.error) {
            const errorObj = {
              error: resp.error,
            }
            const errorResponse = [errorObj];

            setVisualizationType(VisualizationType.TABLE);
            setResults(errorResponse);
            updateResults(VisualizationType.TABLE, errorResponse);
          } else {
            const data = resp.response.data;
            setVisualizationType(newVisualizationType);
            setResults(data);
            updateResults(newVisualizationType, data);
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        }).finally(() => {
          setIsRunDisabled(false);

        });
    });
  };

  function updateResults(newVisualizationType, newResults = undefined) {
    logger.info('updateResults called. visualization: ' + VisualizationType[newVisualizationType], "6x8T3");
    const visType = newVisualizationType ?? visualizationType;
    if (visType === VisualizationType.TABLE) {
      tableRef.current.callUpdateTable(newResults ?? results);
    } else if (visType === VisualizationType.TIMECHART) {
      timechartRef.current.callUpdateResults(newResults ?? results);
    } else if (visType === VisualizationType.PIE) {
      piechartRef.current.callUpdateResults(newResults ?? results);
    }
  }

  const handleVisualizationChange = (event) => {
    logger.debug(`handleVisualizationChange ${event.target.value}`, "6x8T4");
    setVisualizationType(event.target.value);
    updateResults(event.target.value);
  };

  const [topHeight, setTopHeight] = useState(50); // Initial height as 50% for both panels
  // const [isResizing, setIsResizing] = useState(false);
  let isResizing = false;

  const handleSplitterMouseDown = () => {
    logger.debug('handleSplitterMouseDown, starting resize', "6x8T5");
    // setIsResizing(true);
    isResizing = true;
    document.body.style.cursor = 'row-resize';
  };

  const handleMouseMove = (e) => {
    if (!isResizing) return;
    
    // Calculate new height as a percentage of the container
    const containerRect = document.querySelector('.editor-and-results').getBoundingClientRect();
    const relativeY = e.clientY - containerRect.top;
    const newTopHeight = (relativeY / (containerRect.height - SPLITTER_HEIGHT)) * 100;
    // console.log(`handleMouseMove, newTopHeight: ${newTopHeight} relativeY: ${relativeY} containerRect.height: ${containerRect.height}`);
    
    // Limit panel resizing within 10% and 90% of the container height
    if (newTopHeight >= 10 && newTopHeight <= 90) {
      setTopHeight(newTopHeight);
    }
  };

  const handleMouseUp = () => {
    if (isResizing) {
      logger.debug('handleMouseUp, stopping resize', "6x8T6");
      isResizing = false;
      document.body.style.cursor = 'default';
      editorRef.current.callLayout();
      setTimeout(() => {
        editorRef.current.callLayout();
      }, 0);
    }
  };
  document.addEventListener('mousemove', handleMouseMove.bind(this));
  document.addEventListener('mouseup', handleMouseUp.bind(this));

  useEffect(() => {

    // return () => {
    //   console.log('cleanup mousemove and mouseup');
    //   document.removeEventListener('mousemove', handleMouseMove.bind(this));
    //   document.removeEventListener('mouseup', handleMouseUp.bind(this));
    // };
  }, [isResizing, topHeight]);

  return (
    <div className="query-container">
      <div className="query-navbar">
        <button disabled={isRunDisabled}
          className="action-button-small query-element run-query-button"
          onClick={onRunQuery}
          title="Run query (Ctrl + Enter)"
        >
          Run
          {isRunDisabled ? <div className="spinner" ></div> : <span></span>}
        </button>
        <div className="visualization-options">
          <input type="radio" id="table" name={'visualizationType' + props.index.toString()} value="table"
            checked={visualizationType === VisualizationType.TABLE}
            onChange={handleVisualizationChange}
          />
          <label htmlFor="table">Table</label>
          <input type="radio" id="timechart" name={'visualizationType' + props.index.toString()} value="timechart"
            checked={visualizationType === VisualizationType.TIMECHART}
            onChange={handleVisualizationChange}
          />
          <label htmlFor="timechart">Timechart</label>
          <input type="radio" id="pie" name={'visualizationType' + props.index.toString()} value="pie"
            checked={visualizationType === VisualizationType.PIE}
            onChange={handleVisualizationChange}
          />
          <label htmlFor="pie">Pie</label>
        </div>
      </div>

      <div className="query-element editor-and-results">
        <MonacoEditor ref={editorRef} height={topHeight} index={props.index} isSandBox={isSandboxUser(props.user)}  ctrlEnterClicked={() => {
          props.ctrlEnterClicked();
        }}></MonacoEditor>
        <div className="splitter" onMouseDown={handleSplitterMouseDown} style={{height: SPLITTER_HEIGHT}}></div>
        <Table ref={tableRef} visible={visualizationType === VisualizationType.TABLE} height={100-topHeight} />
        <Timechart ref={timechartRef} visible={visualizationType === VisualizationType.TIMECHART} height={100-topHeight}/>
        <Piechart ref={piechartRef} visible={visualizationType === VisualizationType.PIE} height={100-topHeight} />

      </div>



    </div>

  )
});

export default Query


function processQueryCode(queryCodeInitial: string): [queryCode: string, newVisualizationType: VisualizationType] {
  const queryCode = queryCodeInitial.trim();
  //TODO: Check if splits by line end
  const words = queryCode.split(/\s+/).filter(word => word.length > 0);
  if (words.length <= 1) {
    return [queryCode, undefined /* original VisualizationType */];
  }

  const lastWord = words[words.length - 1].toLowerCase();
  const secondToLastWord = words[words.length - 2].toLowerCase();
  if (secondToLastWord === 'visualize') {
    const wordsExceptLastTwo = words.slice(0, words.length - 2);
    const queryCodeWithoutChart = wordsExceptLastTwo.join(' ');
    if (lastWord === 'timechart') {
      return [queryCodeWithoutChart, VisualizationType.TIMECHART];
    } else if (lastWord === 'piechart') {
      return [queryCodeWithoutChart, VisualizationType.PIE];
    } else if (lastWord === 'table') {
      return [queryCodeWithoutChart, VisualizationType.TABLE];
    }
  }
  return [queryCode, undefined /* original VisualizationType */];
}

