import React, { useState } from 'react';
import './challenges.css';
import Challenge1 from './challenge1';
import Challenge2 from './challenge2';
import Challenge3 from './challenge3';
import Challenge4 from './challenge4';
import Challenge5 from './challenge5';


const Challenges = (() => {

  const [challengeIndex, setChallengeIndex] = useState(1);
  const minChallenge = 1;
  const maxChallenge = 5;
  const handleNext = (event) => {
    event.preventDefault();
    setChallengeIndex(challengeIndex + 1);
  }

  const handlePrev = (event) => {
    event.preventDefault();
    setChallengeIndex(challengeIndex - 1);
  }

  const isNextEnabled = () => challengeIndex < maxChallenge;
  const isPrevEnabled = () => challengeIndex > minChallenge;

  function getChallengeTitle(): React.ReactNode {
    switch (challengeIndex) {
      case 1: return "Get familiar";
      case 2: return "Log IDs";
      case 3: return "Timecharts";
      case 4: return "Piecharts";
      case 5: return "Joins";
      default: return "Unknown challenge";
    }
  }

  return (
    <div className="challenges-root">
      <h3>Sandbox {challengeIndex}/{maxChallenge}: {getChallengeTitle()}</h3>

      { 
       challengeIndex == 1 ? (<Challenge1/>)
          :( challengeIndex == 2 ? <Challenge2/> 
          :( challengeIndex == 3?  <Challenge3/> 
          : (challengeIndex == 4? <Challenge4/> : <Challenge5/>)))}

      <div className='challenge-nav-container' >
        <button className='action-button-small ' id='prevChallenge' onClick={handlePrev} disabled={!isPrevEnabled()}>Previous</button>
        <button className='action-button-small ' id='nextChallenge' onClick={handleNext} disabled={!isNextEnabled()}>Next</button>
      </div>
    </div>
  );
});

export default Challenges;
