import './challenges.css';
import ChallengeSolution from './challenge-solution';


const Challenge3 = (() => {



  return (
    <div className="challenge-inner-container">
      {/* TODO: The idea here is to correlate an error to an earlier log. For that, we need better errors */}
      <p>Obics analytics engine can do aggregations in real-time for huge data sets. 
            One of the most common use cases is to track the number of page views over time. Let's create a timechart that shows the number of page views per day.
            Hint: The <strong>LogId</strong> that describes a page view is <strong>apag</strong></p>
            
        <ChallengeSolution solutionQuery="SELECT  toStartOfDay(Time) AS date,
 COUNT(*) AS times
FROM Logs
WHERE LogId = 'apag'
GROUP BY date
VISUALIZE TIMECHART"
/>
<p>You can visualize a timechart by finishing your query with `VISUALIZE PIECHART` or choosing "Timechart" in the options button near "Run" on top of the page.</p>
<p><strong>Bonus: </strong>Instead of filtering by LogId, which not all logging systems have, you can filtern by message patterns. 
For example, since all "apag" logs start with "Analytics.pageView", so you can filter to them using the "startsWith" function.</p>

<ChallengeSolution solutionQuery="SELECT  toStartOfDay(Time) AS date,
 COUNT(*) AS times
FROM Logs
WHERE startsWith(Message,'Analytics.pageView')
GROUP BY date
VISUALIZE TIMECHART"
/>

    </div>
  );
});

export default Challenge3;
