import { forwardRef, useImperativeHandle, useState } from 'react';
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import { AgCharts } from 'ag-charts-react';
import { AgChartOptions } from "ag-charts-community";
import logger from '../utils/logger';

interface TimechartProps {
    visible: boolean;
    height: number;
}

const series = [
    {
        type: 'line',
        xKey: 'date',
        yKey: 'value',
        stroke: 'blue', // Optional styling
    },
];

const axes = [
    {
      type: 'time',
      position: 'bottom',
      label: {
        format: '%Y-%m-%d %H:%M',
      },
    },
    {
      type: 'number',
      position: 'left',
    },
  ];

const Timechart = forwardRef((props: TimechartProps, ref) => {

    useImperativeHandle(ref, () => ({
        callUpdateResults: updateResults,
    }));

    const updateResults = (results) => {
        logger.debug('updateResults: timechart data:', "YpUVU");
        logger.debug(results, "MjfG6");
        if (results.length > 0) {
            const firstRow = results[0];
            const timeColumn = getTimeColumn(firstRow);
            const valueColumn = getValueColumn(firstRow, timeColumn);
            logger.info(`timeColumn ${timeColumn} valueColumn ${valueColumn}`, "KBDb2");


            const data = results.map((row) => {
                return {
                    date: new Date(row[timeColumn]),
                    value: parseFloat(row[valueColumn]),
                };
            }).sort((a, b) => a.date - b.date);
                
            setOptions({
                data: data,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                series: series as any,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                axes: axes as any,
            } );

        }
    }

    const [options, setOptions] = useState<AgChartOptions>({}
);
  
    return <div
        className='results-view'
        style={{
            height: props.height.toString() + '%',
            visibility: props.visible ? 'visible' : 'hidden',
            display: props.visible ? 'block' : 'none',
        }}
    ><AgCharts options={options} style={{width: '100%', height: '100%', display: 'block'}}/></div>;
});


export default Timechart;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getValueColumn(firstRow: any, timeColumn: string) {
    let valueColumn = Object.keys(firstRow).find((key) => key.toLowerCase().includes('value'));
    if (!valueColumn) {
        valueColumn = Object.keys(firstRow).find((key) => key.toLowerCase().includes('result'));
    }
    if (!valueColumn) {
        valueColumn = Object.keys(firstRow).find((key) => key.toLowerCase().includes('count'));
    }
    if (!valueColumn) {
        valueColumn = Object.keys(firstRow).find((key) => key.toLowerCase().includes('sum'));
    }
    if (!valueColumn) {
        valueColumn = Object.keys(firstRow).find((key) => key.toLowerCase().includes('avg'));
    }
    if (!valueColumn) {
        valueColumn = Object.keys(firstRow).find((key) => key.toLowerCase().includes('average'));
    }
    if (!valueColumn) {
        valueColumn = Object.keys(firstRow).find((key) => key.toLowerCase().includes('total'));
    }
    if (!valueColumn) {
        valueColumn = Object.keys(firstRow).find((key) => key.toLowerCase().includes('amount'));
    }
    if (!valueColumn) {
        valueColumn = Object.keys(firstRow)[1];
    }
    if (valueColumn == timeColumn) {
        valueColumn = Object.keys(firstRow)[0];
    }
    return valueColumn;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getTimeColumn(firstRow: any) {
    const keys = Object.keys(firstRow);
    const best = keys.map((key, index) => {
        let score = 0;
        const lower = key.toLowerCase();
        const value= firstRow[key];
        if (isValidDate(value)) {
            score += 3;
        }
        if (lower.includes('()')) {
            score -= 1;
        }
        if (lower.includes('time')) {
            score += 1;
        }
        if (lower.includes('date')) {
            score += 1;
        }
        if (lower.includes('day')) {
            score += 1;
        }
        if (lower.includes('month')) {
            score += 1;
        }
        if (lower.includes('year')) {
            score += 1;
        }
        return [index, score];
    }).sort((a, b) => b[1] - a[1])[0][0];
    return keys[best];
    
}


function isValidDate(date) {
    const parsedDate = new Date(date);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
    return parsedDate instanceof Date && !isNaN(parsedDate as any);
  }