// import DropdownMenu from "./DropDownMenu"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'

interface SidebarLinkProps {
  enabled: boolean;
  icon: IconDefinition;
  link: string;
  text: string;
}

const SidebarLink = ({ icon, link, text, enabled }: SidebarLinkProps) => {

  // const [isOpen, setIsOpen] = useState(false);

  // const toggleMenu = () => {
  //   setIsOpen(!isOpen);
  // };

  return (
    <div className="sidebar-link">
      { enabled? (
      <a href={link} > 
        <FontAwesomeIcon icon={icon} />
        {/* <span className="icon">{icon}</span> */}
        <span className="sidebar-link-text">{text}</span>
      </a>
      ) : (
        <span className="disabled-link">
          <FontAwesomeIcon icon={icon} />
          {/* <span className="icon">{icon}</span> */}
          <span className="sidebar-link-text">{text}</span>
        </span>
      )}
    </div>
      
  );
}

export default SidebarLink
