import { forwardRef, useImperativeHandle, useState } from 'react';
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import { AgCharts } from 'ag-charts-react';
import { AgChartOptions } from "ag-charts-community";
import logger from '../utils/logger';

interface PiechartProps {
    visible: boolean;
    height: number;
}

const Piechart = forwardRef((props: PiechartProps, ref) => {

    useImperativeHandle(ref, () => ({
        callUpdateResults: updateResults,
    }));

    const updateResults = (results) => {
        logger.debug('piechart updateResults.length ' + results?.length, "6x8T1");
        if (results.length > 0) {
            const firstRow = results[0];
            
            
            const amountColumn = getAmountColumn(firstRow);
            const assetColumn = getAssetColumn(firstRow, amountColumn);
            logger.debug('asset column1='+assetColumn+' amount column='+amountColumn, "hQq7Y");

            const allInts = results.every((row) => isInteger(row[amountColumn]));
            logger.debug('allInts' + allInts, "sm0MR");
            const data = results.map((row) => {
                return {
                    asset: row[assetColumn] ?? '',
                    amount: allInts ? parseInt(row[amountColumn]) : parseFloat(row[amountColumn]),
                };
            });

            logger.debug('updateResults: piechart data:', "P5xEP");
            logger.debug(data, "Yiej8");


            const series = [
                {
                    type: 'pie',
                    angleKey: 'amount',
                    calloutLabelKey: 'asset',
                    sectorLabelKey: 'amount',
                    legendItemKey: "asset",
                    sectorLabel: {
                        color: 'white',
                        fontWeight: 'bold',
                    },
                },
            ];
            
                
            setOptions({
                data: data,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                series: series as any,
            } );

        }
    }

    const [options, setOptions] = useState<AgChartOptions>({}
);
  
    return <div
        className='results-view'
        style={{
            height: props.height.toString() + '%',
            visibility: props.visible ? 'visible' : 'hidden',
            display: props.visible ? 'block' : 'none',
        }}
    ><AgCharts options={options} style={{width: '100%', height: '100%', display: 'block'}}/></div>;
});


export default Piechart;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getAmountColumn(firstRow: any) {
    const keys = Object.keys(firstRow);


    const best = keys.map((key, index) => {
        let score = 0;
        const keyLower = key.toLowerCase();
        const value= firstRow[key];

        const isNumber = !isNaN(Number(value));

        if (isNumber) {
            score += 3;
        }
        if (keyLower.includes('times')
            || keyLower.includes('amount')
            || keyLower.includes('value')
            || keyLower.includes('size')) {
            score += 1;
        }
        
        return [index, score];
    }).sort((a, b) => b[1] - a[1])[0][0];
    return keys[best];
    
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getAssetColumn(firstRow: any, amountColumn: string) {
    const keys = Object.keys(firstRow);
    const best = keys.map((key, index) => {
        let score = 0;
        const value= firstRow[key];

        if (key == amountColumn){
            score -= 1000;
        }

        const isNumber = !isNaN(Number(value));
        if (!isNumber) {
            score += 1;
        }
        
        return [index, score];
    });
    logger.debug('best asset ' + best, "Zl05R");
    const best1=best.sort((a, b) => b[1] - a[1])[0][0];
    return keys[best1];

}

function isInteger(str) {
    const parsed = parseInt(str, 10);
    return !isNaN(parsed) && parsed.toString() === str;
  }